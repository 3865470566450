.contact-section1-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 200px;
  width: 100vw;
  height: max-content;
  background: var(--extra-light-gray-color);

  h1 {
    font-size: var(--h2-font);
    font-weight: var(--medium);
    margin: 20px;
    text-transform: capitalize;
    color: transparent;
    background-image: linear-gradient(
      to right,
      var(--magenta-bright-color),
      var(--violet-dark-color)
    );
    background-clip: text;
    -webkit-background-clip: text;
    text-transform: uppercase;
  }

  .contact-section1-details {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    align-items: flex-start;
    flex-wrap: nowrap;

    .contact-section1-intro {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      opacity: 0;
      animation: slideInFromLeft 1s ease-out forwards;

      .span-shape {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .shape {
          position: relative;
          width: 50px;
          height: 2px;
          background-color: var(--purple-color);

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: var(--purple-color);
            height: 2px;
          }

          &::before {
            width: 30px;
            top: -5px;
            left: 0;
          }

          &::after {
            width: 30px;
            bottom: -5px;
            left: 0;
          }
        }

        span {
          font-size: 16px;
          color: var(--purple-color);
          font-weight: var(--medium);
          text-transform: uppercase;
        }
      }

      h3 {
        font-size: var(--h2-font);
        font-weight: var(--regular);
        margin-top: 10px;
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
      }

      p {
        font-size: var(--normal-font);
        text-align: justify;
        line-height: 1.6;
        margin-bottom: 10px;
      }

      .contact-info {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h4 {
          font-size: var(--h3-font);
          font-weight: var(--regular);
          margin-top: 10px;
          text-transform: capitalize;
          color: transparent;
          background-image: linear-gradient(
            to right,
            var(--magenta-bright-color),
            var(--violet-dark-color)
          );
          background-clip: text;
          -webkit-background-clip: text;
        }

        ul {
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }
      }
    }
  }

  .contact-form-container {
    display: flex;
    flex: 1;
    height: auto;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;
      width: 550px;

      .selected-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
        width: 100%;

        .form-group {
          position: relative;
          width: 100%;

          input[type="email"],
          input[type="text"],
          input[type="password"],
          input[type="file"],
          textarea,
          select {
            padding: 12px;
            width: 100%;
            max-width: 100%;
            border-radius: 4px;
            box-sizing: border-box;
            outline: none;
            border: 1px solid #ccc;
            background-color: #fff;
            transition: border-color 0.3s ease;

            &:focus {
              border-color: #0677c8;
            }

            &.invalid {
              border-color: red;
            }
          }

          .mobile-container {
            display: flex;
            align-items: center;
            gap: 10px;

            select {
              width: 200px;
            }

            input {
              flex-grow: 1;
            }
          }

          label {
            position: absolute;
            top: -12px;
            left: 12px;
            font-size: 14px;
            background: #fff;
            color: #666;
            pointer-events: none;
            transition: all 0.2s ease;
            z-index: 3;
            padding: 0 5px;
          }
        }
      }
    }
  }
}
