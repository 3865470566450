.file-upload-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px 0px 20px 0px;
}

.file-uploader {
  input {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
  }
  .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
}

.content-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 50px;
}

.file-preview {
  width: 48%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;

  .preview-header {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;

    button {
      background: #007bff;
      border: none;
      outline: none;
      padding: 10px 15px;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        background: #0056b3;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .docx-preview {
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal {
    background: #fff;
    width: 400px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;

    h4 {
      margin-bottom: 15px;
      font-size: 18px;
    }

    .modal-body {
      max-height: 300px;
      overflow-y: auto;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      background: #f9f9f9;
      text-align: left;
    }

    .close-btn {
      margin-top: 15px;
      padding: 10px 15px;
      background: #dc3545;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: #a71d2a;
      }
    }
  }
}

.file-form {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  h3 {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-top: 12px;
    font-weight: bold;
    font-size: 14px;
    color: #444;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-top: 6px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background-color: #f9f9f9;
    transition: border 0.3s ease-in-out;

    &:focus {
      border-color: #007bff;
      outline: none;
      background-color: #fff;
    }
  }

  textarea {
    min-height: 120px;
    resize: vertical;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: Arial, sans-serif;
    line-height: 1.5;
  }

  .dynamic-field {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    textarea,
    input {
      flex: 1;
    }

    button {
      padding: 6px 10px;
      background-color: #dc3545;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #c82333;
      }
    }
  }

  .add-button {
    margin-top: 10px;
    background-color: #28a745;
    padding: 8px 12px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #218838;
    }
  }

  button {
    margin-top: 15px;
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
}
