.career-section2-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 200px;
  width: 100vw;
  height: max-content;
  background: var(--white-color);

  .career-section2-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .career-section2-intro {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      opacity: 0;
      animation: slideInFromLeft 1s ease-out forwards;

      h3 {
        font-size: var(--h1-font);
        font-weight: var(--regular);
        margin-top: 10px;
        text-transform: capitalize;
        color: transparent;
        background-image: linear-gradient(
          to right,
          var(--magenta-bright-color),
          var(--violet-dark-color)
        );
        background-clip: text;
        -webkit-background-clip: text;
        text-align: center;
      }

      .career-section2-jd-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

      }
    }
  }
}
